<template>
    <section class="cotizaciones-realizadas container-fluid mt-4">
        <template v-if="!showMateriales">
            <div class="row justify-content-end">
                <div class="col-auto">
                    <div v-if="contar == 0" class="col-auto my-auto px-1 icon-option">
                        <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                            <button class="btn btn-square32-5d" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                        </el-tooltip>
                    </div>
                    <div v-if="contar > 0" class="col-auto filtro-contador my-auto px-1">
                        <div class="d-flex border-general br-4">
                            <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltros">
                                <i class="icon-filter text-white" />
                            </button>
                            <div class="d-middle px-2">
                                <p class="f-12"> {{ contar }} </p>
                                <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="clearAllFilters" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div v-for="(data,idx) in cotizacionesPendientes.data" :key="idx" class="col-auto" @click="verMateriales(data)">
                    <card-cotizacion :numero="data.referencia" :fechaCotizacion="data.fecha_cotizacion" :fechaVencimiento="data.fecha_vencimiento" :cantMateriales="data.cantidad_materiales"  />
                </div>
            </div>
            <p v-if="cotizacionesPendientes.data == 0" class="text-center">No hay cotizaciones</p>
            <div v-if="cotizacionesPendientes.data && cotizacionesPendientes.data.length" class="d-flex justify-content-center mt-4">
                <el-pagination
                    :current-page.sync="cotizacionesPendientes.pagination.current_page"
                    :page-size.sync="cotizacionesPendientes.pagination.per_page"
                    :total="cotizacionesPendientes.pagination.total"
                    :layout="cotizacionesPendientes.pagination.botones"
                    background
                    @current-change="listarPag"
                    @size-change="listarPag"
                />
            </div>
        </template>
        <ver-cotizacion-pendiente v-if="showMateriales" :idCotizacion="idCotizacion" @goBack="goBack" />
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="col-11 mb-3">
                    <p class="input-label-top">Cotización</p>
                    <el-select v-model="filter.referencia" clearable filterable placeholder="Seleccionar cotización" size="small" class="w-100">
                        <el-option v-for="item in select_cotizaciones" :key="item.id" :label="item.referencia" :value="item.referencia" />
                    </el-select>
                </div>
                <div class="col-11 mb-3">
                    <p class="input-label-top">Cantidad materiales</p>
                    <el-slider v-model="range_value"
                        range 
                        :min="Number(rangeCantMaterials.cantidad_materiales_minimo)"
                        :max="Number(rangeCantMaterials.cantidad_materiales_maximo)"
                        :disabled="disableSlider"
                    />
                </div>
                <div class="col-11 mb-4">
                    <p class="input-label-top">Rango de contización</p>
                     <el-date-picker
                        v-model="range_cotizacion"
                        type="daterange"
                        range-separator="a"
                        size="small"
                        start-placeholder="Fecha Inicio"
                        value-format="yyyy-MM-dd"
                        class="w-100 mt-1"
                        end-placeholder="Fecha Fin">
                    </el-date-picker>
                </div>
                <div class="col-11 mb-3">
                    <p class="input-label-top">Rango de vencimiento</p>
                     <el-date-picker
                        v-model="range_vencimiento"
                        type="daterange"
                        range-separator="a"
                        size="small"
                        start-placeholder="Fecha Inicio"
                        value-format="yyyy-MM-dd"
                        class="w-100 mt-1"
                        end-placeholder="Fecha Fin">
                    </el-date-picker>
                </div>
               
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearAllFilters"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="doFilter"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import cardCotizacion from './components/cardCotizacion.vue'
import verCotizacionPendiente from './verCotizacionPendiente.vue'
export default {
    components: {
        cardCotizacion,
        verCotizacionPendiente,
    },
    data() {
        return {
            showMateriales: false,
            idCotizacion: -1,
        }
    },
    async created () {
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', ['proveedores.usuarios']);
        await this.listar()
        this.$store.dispatch('navigation/breadcumbs/spliceBreadcumbs',[
            {
                position: 2,
                breadcumb: {
                    name: this.informationProvider.nombre,
                    route: 'proveedores.usuarios.cotizaciones.pendientes',
                }
            }
        ])
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            informationProvider: 'users_providers/usersProviders/informationProvider',
            cotizacionesPendientes: 'users_providers/usersProviders/cotizacionesPendientes',
            select_cotizaciones: 'selects/selects/select_cotizaciones',
            rangeCantMaterials: 'users_providers/usersProviders/rangeCantMaterials',
            filterPendientes: 'users_providers/usersProviders/filterPendientes',
        }),
        filter:{
            get(){ return this.filterPendientes },
            set(val){ this.set_filters_pendientes(val) }
        },
        range_value:{
            get(){ return [this.filter.cantidad_materiales_min, this.filter.cantidad_materiales_max] },
            set(val){ this.filter.cantidad_materiales_min = val[0], this.filter.cantidad_materiales_max = val[1] },
        },
        range_cotizacion:{
            get(){ 
                if(!this.filter.fecha_cotizacion_inicio) return
                return [this.filter.fecha_cotizacion_inicio, this.filter.fecha_cotizacion_fin]
            },
            set(val){ 
                this.filter.fecha_cotizacion_inicio = val !== null ? val[0] : null,
                this.filter.fecha_cotizacion_fin = val !== null ? val[1] : null 
            },
        },
        range_vencimiento:{
            get(){ 
                return this.filter.fecha_vencimiento_inicio !== null
                    ? [this.filter.fecha_vencimiento_inicio, this.filter.fecha_vencimiento_fin]
                    : null
            },
            set(val){
                this.filter.fecha_vencimiento_inicio = val !== null ? val[0] : null,
                this.filter.fecha_vencimiento_fin = val !== null ? val[1] : null
            },
        },
        disableSlider(){
            return [
                this.rangeCantMaterials.cantidad_materiales_minimo,
                this.rangeCantMaterials.cantidad_materiales_maximo
            ].every(el => el == 0)
        },
        contar(){
            return this.filter.count();
        }
    },
    methods: {
        ...mapMutations({
            set_info_top: 'users_providers/usersProviders/set_info_top',
            set_filters_pendientes: 'users_providers/usersProviders/set_filters_pendientes',
        }),
        ...mapActions({
            Action_list_cotizaciones: 'users_providers/usersProviders/Action_list_cotizaciones_pendientes',
            Action_list_materiales_cotizaciones: 'users_providers/usersProviders/Action_list_materiales_cotizaciones',
            Action_get_range_materials: 'users_providers/usersProviders/Action_get_range_materials',
            Action_get_cantidad_value_ver_materials: 'users_providers/usersProviders/Action_get_cantidad_value_ver_materials',
            Action_get_select_cotizaciones: 'selects/selects/Action_get_select_cotizaciones',
            Action_get_select_measure_units: 'selects/selects/Action_get_select_measure_units',
            clear_filter_materiales: 'users_providers/usersProviders/clean_filters_ver_pendientes',
            clear_filter: 'users_providers/usersProviders/clean_filters_pendientes',
        }),
        async listar(){
            await Promise.all([
                this.Action_get_range_materials({id_user: this.user.id, tipo: 1}),
                this.Action_get_select_cotizaciones({id_user: this.user.id, tipo: 1}),
                this.Action_get_select_measure_units(),
            ])
            this.clear_filter()
            await this.Action_list_cotizaciones({page: 1}),
            this.set_info_top({})
        },
        abrirModalFiltros(){
            this.$refs.abrirModalFiltros.toggle()
        },
        async doFilter(){
            await this.Action_list_cotizaciones();
            this.$refs.abrirModalFiltros.toggle()
        },
        async verMateriales(data){
            this.idCotizacion = data.id
            this.set_info_top(data)
            await this.Action_get_cantidad_value_ver_materials({id_cotizacion: data.id})
            this.clear_filter_materiales()
            await this.Action_list_materiales_cotizaciones({id: data.id, payload: {page: '1'}, cotizacion: 'pendientes'});
            this.showMateriales = !this.showMateriales
        },
        async clearAllFilters(){
            this.clear_filter()
            await this.Action_list_cotizaciones();
        },
        goBack(){
            this.showMateriales = false
            this.listar()
        },
        async listarPag(pag){
            await this.Action_list_cotizaciones({page: pag});
        }
    },

}
</script>

<style lang="scss" scoped>
.cotizaciones-realizadas{
    background: white;
}
</style>