<template>
    <section class="ver-cotizacion-pendiente container-fluid">
        <div class="row justify-content-between">
            <div class="d-middle cr-pointer icon-option" @click="$emit('goBack')">
                <i class="icon-arrow-left f-18" />
                <p class="f-15">Regresar cotizaciones pendientes</p>
            </div>
            <div class="col-auto d-flex">
                <button class="btn btn-general f-12 px-4" @click="guardarCotizacion">Guardar cotización</button>
                <div v-if="contar == 0" class="col-auto my-auto px-1 icon-option">
                    <el-tooltip content="Filtro" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d" @click="abrirModalFiltrosVer">
                            <i class="icon-filter text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div v-if="contar > 0" class="col-auto filtro-contador my-auto px-1">
                    <div class="d-flex border-general br-4">
                        <button class="btn btn-square32-5d cr-pointer" @click="abrirModalFiltrosVer">
                            <i class="icon-filter text-white" />
                        </button>
                        <div class="d-middle px-2">
                            <p class="f-12"> {{ contar }} </p>
                            <i class="icon-close-circle f-11 text-muted pl-1 cr-pointer" @click="clearAllFilters" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <tabla-general :servidorData="materialesCotizaciones" @paginar="listarPag" :mostrarBuscador="false" alturaTabla="500px" :usarServidor="true" :usarPaginacion="true">
            <el-table-column label="Referencia" prop="referencia" align="center" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600">
                        {{ scope.row.referencia }}
                    </p>
                </template>
            </el-table-column>
             <el-table-column label="Materiales" prop="materiales">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2 f-600 tres-puntos">
                        {{ scope.row.nombre }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="U.M" prop="um" width="120">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.configuracion_unidad_medidas.unidad_medida }} ({{ scope.row.configuracion_unidad_medidas.sigla }})
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Cantidad" prop="cantMateriales" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        {{ scope.row.cantidad }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column label="Moneda" align="center">
                <template slot-scope="scope">
                    <el-select v-model="scope.row.id_moneda" placeholder="Seleccionar" size="small" class="w-100" filterable>
                        <el-option v-for="item in select_monedas" :key="item.id" :label="item.nombre" :value="item.id" />
                    </el-select>
                </template>
            </el-table-column>
            <el-table-column label="Valor Unitario" prop="valor" align="center" width="200">
                <template slot-scope="scope">
                    <CustomCurrencyInput :value="Number(scope.row.valor)" 
                        :options="{ 
                            currency: currencyFormat(scope.row.id_moneda), 
                            distractionFree: {
                                hideNegligibleDecimalDigits: true,
                                hideCurrencySymbol: true,
                                hideGroupingSymbol: true
                            }
                        }" 
                        @change="contarEdits($event, scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column label="Valor total" prop="valor" align="center">
                <template slot-scope="scope">
                    <p class="f-12 text-muted2">
                        $ {{ scope.row.valor * Number(scope.row.cantidad) }}
                    </p>
                </template>
            </el-table-column>
        </tabla-general>
        <!-- partials -->
        <modal-lateral ref="abrirModalFiltrosVer" titulo="Filtro">
            <div class="row mx-0 justify-content-center my-2">
                <div class="row mx-3 justify-content-center my-2">
                    <div class="col-12 mb-5">
                        <p class="input-label-top">Unidad de medida</p>
                        <el-select v-model="filter.id_unidad_medida" clearable filterable placeholder="Seleccionar unidade de medida" size="small" class="w-100">
                            <el-option 
                                v-for="item in select_measure_units"
                                :key="item.id"
                                :label="`${item.unidad_medida} (${item.sigla})`"
                                :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-11 mb-4">
                        <p class="input-label-top">Cantidad de material</p>
                        <el-slider v-model="range_amount"
                            range :min="Number(rangeCantValVerMaterials.cantidad_minima)"
                            :max="Number(rangeCantValVerMaterials.cantidad_maxima)" 
                        />
                    </div>
                    <div class="col-11 mb-3">
                        <p class="input-label-top">Rango de valor</p>
                        <el-slider v-model="range_value"
                            range :min="Number(rangeCantValVerMaterials.valor_unidad_minimo)"
                            :max="Number(rangeCantValVerMaterials.valor_unidad_maximo)" 
                        />
                    </div>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="clearAllFilters"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="doFilter"> Filtrar </button>
            </div>
        </modal-lateral>

        <modal ref="abrirModalConfirmacion" tamano="modal" class="overflow-hidden" titulo="Guardar Cotización" adicional="Guardar" @adicional="enviarCotizacion">
            <div class="py-4">
                <p class="text-center text-muted f-14">¿Está seguro que quiere guardar esta cotización?</p>
                <p class="text-center text-muted f-14">Valores de materiales modificados {{ materialesEditados.length }} de {{ materialesCotizaciones.total }}</p>
            </div>
        </modal>
    </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    props: {
        idCotizacion: {
            type: Number,
            default: -1
        },
    },
    data() {
        return {
            filtersCount: 0,
            materialesEditados: [],
            moneda: [],
            unidad: '',
            cantidad: '',
            valor: '',

        }
    },
    created () {
        this.clear_filter();
    },
    computed: {
        ...mapGetters({
            materialesCotizaciones: 'users_providers/usersProviders/materialesCotizaciones',
            rangeCantValVerMaterials: 'users_providers/usersProviders/rangeCantValVerMaterials',
            filterVerPendientes: 'users_providers/usersProviders/filterVerPendientes',
            select_monedas: 'selects/selects/select_monedas',
            select_measure_units: 'selects/selects/select_measure_units',
        }),
        
        filter:{
            get(){ return this.filterVerPendientes },
            set(val){ this.set_filters_ver_pendientes(val) }
        },
        range_value:{
            get(){ return [this.filter.rango_valor_minimo, this.filter.rango_valor_maximo] },
            set(val){ this.filter.rango_valor_minimo = val[0], this.filter.rango_valor_maximo = val[1] },
        },
        range_amount:{
            get(){ return [this.filter.cantidad_material_minimo, this.filter.cantidad_material_maximo] },
            set(val){ this.filter.cantidad_material_minimo = val[0], this.filter.cantidad_material_maximo = val[1] },
        },
        contar(){
            return this.filter.count();
        }
    },
    methods: {
        ...mapActions({
            Action_list_materiales_cotizaciones: 'users_providers/usersProviders/Action_list_materiales_cotizaciones',
            Action_set_price_materials: 'users_providers/usersProviders/Action_set_price_materials',
            clear_filter: 'users_providers/usersProviders/clean_filters_ver_pendientes',
        }),
        ...mapMutations({
            set_filters_ver_pendientes: 'users_providers/usersProviders/set_filters_ver_pendientes',
        }),
        currencyFormat(e) {
            return this.select_monedas.find(el => el.id == e)?.sigla ?? 'USD'
        },
        abrirModalFiltrosVer(){
            this.$refs.abrirModalFiltrosVer.toggle()
        },
        clearFilters(){
            this.$refs.abrirModalFiltrosVer.toggle()
        },
        guardarCotizacion(){
            this.$refs.abrirModalConfirmacion.toggle()
        },
        async enviarCotizacion(){
            let payload = new Array
            let totalMateriales = this.materialesCotizaciones?.total
            let materialesEditados = this.materialesEditados.length
            let materialesNoEditados = Number(totalMateriales) - Number(materialesEditados)
            this.materialesEditados.forEach( e => payload.push({ valor: e.valor, id_cotizacion_materiales: e.id, id_moneda: e.id_moneda }) )
            let params = {payload, totalMateriales, materialesEditados, materialesNoEditados}
            this.$refs.abrirModalConfirmacion.toggle()
            this.$notify({title: 'La cotización se esta guardando.', type:'info'})
            await this.Action_set_price_materials(params)
            this.$emit('goBack')
        },
        contarEdits(val, material){
            if (!isNaN(val)) {
                material.valor = Number(val)
                let index = this.materialesEditados.indexOf(material)
                index === -1 ? this.materialesEditados.push(material) : this.materialesEditados[index] = material
            }
        },
        async clearAllFilters(){
            let params = {
                id: this.idCotizacion,
                cotizacion: 'pendientes',
            }
            this.clear_filter()
            await this.Action_list_materiales_cotizaciones(params);
        },
        doFilter(){
            let params = {
                id: this.idCotizacion,
                cotizacion: 'pendientes',
            }
            this.Action_list_materiales_cotizaciones(params)
            this.$refs.abrirModalFiltrosVer.toggle()
        },
        async listarPag(pag){
            let params = {
                id: this.idCotizacion,
                cotizacion: 'pendientes',
                payload: {page: pag}
            }
            await this.Action_list_materiales_cotizaciones( params );
        }
    },
}
</script>

<style lang="scss" scoped>
.ver-cotizacion-pendiente{
    background: white;
}
</style>