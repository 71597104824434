<template>
    <div class="card-cotizacion br-5 shadow29 cr-pointer">
        <div class="bg-general text-white f-14 f-600 py-1 text-center br-t-5">
            <p> Cotización {{ numero }} </p>
        </div>
        <div class="row mx-0 my-2">
            <div class="col-12 col-lg-6 my-auto">
                <p class="f-600 f-12 text-5d">Fecha de creación</p>
            </div>
            <div class="col-12 col-lg-6 f-12 text-center my-auto pr-0"> {{ fechaCotizacion | helper-fecha('DD/MM/YYYY') }} </div>
        </div>
        <div class="row mx-0 my-2">
            <div class="col-12 col-lg-6 my-auto">
                <p class="f-600 f-12 text-5d">Fecha de vencimiento</p>
            </div>
            <div class="col-12 col-lg-6 f-12 text-center my-auto pr-0"> {{ fechaVencimiento | helper-fecha('DD/MM/YYYY') }} </div>
        </div>
        <div class="row mx-0 my-2 shadow-29 py-2 br-b-5">
            <div class="col-12 col-lg-9 my-auto">
                <p class="f-600 f-12 text-5d">Cantidad materiales:</p>
            </div>
            <div class="col-12 col-lg-3 f-12 my-auto"> {{ cantMateriales }} </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        numero: {
            type: [Number,String],
            default: 0
        },
        fechaCotizacion: {
            type: String,
            default: '---'
        },
        fechaVencimiento: {
            type: String,
            default: '---'
        },
        cantMateriales: {
            type: Number,
            default: 0
        },
    },

}
</script>

<style lang="scss" scoped>
.card-cotizacion{
    max-width: 226px;
    min-width: 226px;
    .shadow-29{
        box-shadow: 0px -1px 6px #00000029;
    }
}
</style>